import { RouteProps } from 'react-router-dom';
import LoginPage from 'pages/LoginPage';
import MainPage from 'pages/MainPage';
import NotFoundPage from 'pages/NotFoundPage';
import RegistrationPage from 'pages/RegistrationPage';
import ChangePasswordPage from 'pages/ChangePassword';
import ResetPage from 'pages/ResetPage';
import SignUpPage from 'pages/SignUpPage';
import RestorePage from 'pages/RestorePage';
import SuccessRestorePage from 'pages/SuccessRestorePage';
import EmailVerificationPage from 'pages/EmailVerification';
import SuccessfulPaymentPage from 'pages/SuccessfulPaymentPage';
import OtpCheckPage from 'pages/OtpCheckPage';

import {
  AppRoutes,
  getRestore,
  getRestoreSuccess,
  getRouteChangePassword,
  getRouteEmailVerification,
  getRouteLogin,
  getRouteMain,
  getRouteOtpCheck,
  getRouteRegistration,
  getRouteReset,
  getRouteSuccessfulPayment,
  getSignUp
} from 'shared/consts/routes';

export type AppRoutesProps = RouteProps & {
  authOnly?: boolean;
};

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.MAIN]: {
    path: getRouteMain(),
    element: <MainPage />,
    authOnly: true,
  },
  [AppRoutes.CHANGE_PASSWORD]: {
    path: getRouteChangePassword(),
    element: <ChangePasswordPage />,
    authOnly: true,
  },
  [AppRoutes.LOGIN]: {
    path: getRouteLogin(),
    element: <LoginPage />,
    authOnly: false,
  },
  [AppRoutes.SIGN_UP]: {
    path: getSignUp(),
    element: <SignUpPage />,
    authOnly: false,
  },
  [AppRoutes.REGISTRATION]: {
    path: getRouteRegistration(':token'),
    element: <RegistrationPage />,
    authOnly: false,
  },
  [AppRoutes.OTP_CHECK]: {
    path: getRouteOtpCheck(),
    element: <OtpCheckPage />,
    authOnly: false,
  },
  [AppRoutes.RESET]: {
    path: getRouteReset(':token'),
    element: <ResetPage />,
    authOnly: false,
  },
  [AppRoutes.RESTORE]: {
    path: getRestore(),
    element: <RestorePage />,
    authOnly: false,
  },
  [AppRoutes.RESTORE_SUCCESS]: {
    path: getRestoreSuccess(),
    element: <SuccessRestorePage />,
    authOnly: false,
  },
  [AppRoutes.EMAIL_VERIFICATION]: {
    path: getRouteEmailVerification(),
    element: <EmailVerificationPage />,
    authOnly: false,
  },
  [AppRoutes.SUCCESSFUL_PAYMENT]: {
    path: getRouteSuccessfulPayment(),
    element: <SuccessfulPaymentPage />,
    authOnly: true,
  },
  [AppRoutes.NOT_FOUND]: {
    path: '*',
    element: <NotFoundPage />,
    authOnly: false,
  },
};
