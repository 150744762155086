export const translationEn = {
  shared: {
    error: {
      base: 'An error occurred',
      invalidRequest: 'Invalid request content',
      required: 'Required field',
      email: 'Invalid email',
      minSixLetter: 'Minimum 6 characters',
      copy: 'An error occurred while copying',
      data: 'An error occurred while loading data',
      video: 'Your browser does not support the video tag',
      timeSessionExpired: 'Session timed out',
      download: 'An error occurred while downloading the file',
    },
    message: {
      copy: 'Copied',
    },
    text: {
      yes: 'Yes',
      no: 'No',
      empty: 'No data',
      upTo: 'Up to',
      checkConnectionAndTryAgain:
        'Check your internet connection and try again',
      repeat: 'Try again',
      noData: 'No data',
      continue: 'Continue',
      back: 'Back',
      finish: 'Done',
      ok: 'OK',
    },
    plural: {
      days_one: '{{count}} day',
      days_other: '{{count}} days',
      months_one: '{{count}} month',
      months_other: '{{count}} months',
    },
  },
  theme: {
    toggle: 'Switch theme',
  },
  banner: {
    premiumExpiration: {
      title: 'Hello👋',
      subtitle:
        'Just a gentle reminder that your Premium subscription will expire in 3 days.',
      description: 'Renew now to avoid any interruptions 👇',
      button: 'Renew Premium',
    },
    premiumExpiration_1: {
      title: 'Hi ⏳ Your Premium subscription expires tomorrow.',
      subtitle: `Imagine how much you could lose by going back to the free version: The current VPN on X-Ray technology, which can't be blocked, will stop working.
        
        Renewing now ensures you continue to have secure and unlimited internet access. Make the choice in favour of your convenience and security.
        `,
      description: `Renew right now, don't wait until the last minute! 👇`,
      button: 'Renew Premium',
    },
    premiumExpiration_9: {
      title: `Hi👋 Remember
that we thoughtfully
gave you
14 days of Premium
right from the start?`,
      subtitle: `After the end of the trial period you can continue using the VPN for free, but you will need to install our alternative VPN (no app). X-Ray VPN protocol (via app) is only available on Premium.
        
If you're happy with the current performance of our VPN, we recommend paying now and not thinking about it at the worst possible moment.
        `,
      description:
        'To avoid breaking your head and continue to switch on your VPN with one button, pay for your subscription now 👇',
      button: 'Renew Premium',
    },
    premiumExpired: {
      title: `😿 Your tariff has changed 
to free`,
      subtitle: `If you had a VPN through the app (X-Ray technology) installed, it stopped working. To continue using VPN via app you need to go back to Premium.
      
      For free access you need to install our alternative VPN protocol without apps in the settings of your device. You can do this via ‘Profile’.
      `,
      description:
        'To go back to the Premium plan and continue using the VPN without the headache, click ‘Buy VPN’ 👇',
      button: 'Extend Premium',
    },
    premiumExpired_7: {
      title: `VPN stopped working?`,
      subtitle: `That's because you have a free plan with a speed limit. For free access you need to install our alternative VPN protocol without apps. You can do this via ‘Profile’.`,
      description:
        'To ‘get things back to normal’ and continue to enjoy unlimited internet access, subscribe now 👇',
      button: 'Buy Premium',
    },
    speedLimit: {
      title: `VPN 
      stopped working?`,
      subtitle: `That's because you have a free plan with a speed limit. For free access you need to install our alternative VPN protocol without apps. You can do this via ‘Profile’.`,
      description:
        'To ‘get things back to normal’ and continue to enjoy unlimited internet access, subscribe now 👇',
      button: 'Buy Premium',
    },
    successfulPayment: {
      heading: 'Thank you for choosing us! Payment was successful',
      hint: 'To return to the home page click on the button:',
      button: 'Home',
    },
    noXrayPopup: "X-Ray VPN protocol (via app) is available only on Premium plan.\nTo continue using a VPN that cannot be blocked, please buy Premium plan.",
  },
  society: {
    facebook: 'https://www.facebook.com/groups/privategatevpn',
    telegram: 'https://t.me/privategatevpn',
    vk: 'https://vk.com/privategate_vpn',
    twitter: 'https://twitter.com/privategate_vpn',
    youtube: 'https://youtube.com/@PrivateGateEN?si=ovsYcKMzX25r-fSE',
  },
  links: {
    offer: 'https://privategatevpn.com/offer',
    privacyPolicy: 'https://privategatevpn.com/privacypolicy',
  },
  navigation: {
    faq: 'FAQ',
    howToInstall: 'How to install',
    usefulLinks: 'Useful links',
    howItWorks: 'How it works',
    changePassword: 'Change password',
    enterPromoCode: 'Enter promo code',
    bringFriend: 'Bring a friend',
    support: 'Support',
    logout: 'Logout',
  },
  footer: {
    offer: 'Offer',
    privacyPolicy: 'Privacy Policy',
  },
  howItWorks: {
    howItWorks: {
      title: 'How it works',
      action: 'Start installation',
      description: `
      Private Gate VPN has two VPN protocols and 2 installation methods:
  
  1) Through the APP (RECOMMENDED). 
  X-Ray (VLESS) technology for highly censored regions. The latest VPN protocol that masks your internet traffic.
  
  ✅ Cannot be blocked
  ✅ Works always and everywhere
  ✅ Easy and fast installation
  ✅ Stable
  ✅ Works on any device
  
  2) App-free (FOR ADVANCED). 
  IKEv2 technology for low censorship regions. Suitable for advanced users who like full control.
  
  ✅ App-free installation
  ✅ Works on any device
  ✅ Stable
  ✅ Fast
  
  If you don't have a VPN installed yet, start installing now 
  `,
    },
    gift: {
      title: '14 days Premium for Free',
      action: 'Switch to Premium',
      description: `Hi👋 Remember that we thoughtfully gave you 14 days of Premium from the start?

After the end of the trial period you can continue using the VPN for free, but you will need to install our alternative VPN (without apps). 
X-Ray VPN protocol (via app) is only available on Premium.

If you're happy with the current performance of our VPN, we recommend paying now and not thinking about it at the worst possible moment. 
To avoid breaking your head and keep turning on your VPN with one button, pay for your subscription now 
`,
    },
    free: {
      title: 'Free VPN',
      action: 'Buy VPN',
      description: `😿 Your plan has changed to FREE.

      If you had a VPN installed through the app (X-Ray technology), it stopped working. To continue using VPN through the app, you need to renew the Premium.

For free access, you need to install the alternative app-free VPN protocol in your device's settings. You can do this via creating new connection.

To return to the Premium plan and continue using VPN without headaches, click "Buy VPN" 👇
`,
    },
    bot: {
      title: 'Our Telegram bot',
      links: `Launch the bot at the link:--https://t.me/PrivateGateVPNbot`,
      description: `Not comfortable using it through your browser? We have a convenient bot in Telegram, through which you can also get VPN.

      `,
    },
    chat: {
      title: 'Our Telegram Channel and Chat',
      links: `📣 Our channel--https://t.me/privategatevpn ||
💬 Chat--https://t.me/privategatevpn_chat `,
      description: `Let's always stay connected 🤙

📣 Subscribe to our Telegram channel, where we share unique offers, promotions, news, and lifehacks for safe internet use.

💪 Join now and start receiving exclusive offers and benefits today!

We are waiting for you💚
`,
    },
    feedback: {
      title: 'Leave the feedback',
      links: `👉 All you have to do is take a 2 minute survey via the link--https://forms.gle/Zk5w2PvDdbtfFhYn7`,
      description: `If you have any ideas on how we can improve your Private Gate VPN adventure, please share them. 
We value your opinion and are here to help with any questions you may have. 

`,
    },
  },
  authentication: {
    notify: {
      successRegister: 'Account successfully created',
      passwordResetLinkSent: 'Password reset link sent to email',
      invitationAlreadySent:
        'An invitation has already been sent to the provided email address',
      userNotFound: 'User not found',
      wrongCaptcha: 'Captcha code entered incorrectly',
      passwordChanged: 'Password successfully changed',
      alreadySend:
        'An invitation has already been sent to the specified email address',
    },
    login: {
      title: 'Login',
      noAccountYet: 'Don’t have an account yet?',
      register: 'Register',
      error: 'Incorrect login or password',
      password: 'Password',
      loginButton: 'Login',
    },
    registration: {
      title: 'Registration',
      agreement: 'By registering, you agree to the following documents:',
      and: 'and',
      otp: 'A one-time password for login will be sent to your email',
      offerAgreement: 'Offer',
      privacyPolicy: 'Privacy Policy',
      alreadyHaveAccount: 'Already have an account?',
      login: 'Login',
      next: 'Next',
      email: 'Email',
    },
    password: {
      title: 'Password',
      new: 'New password',
      current: 'Current password',
      create: 'Create a password',
      change: 'Change password',
      restore: 'Restore password',
      reset: 'Reset password',
      forgot: 'Forgot password?',
      enter: 'Enter password',
      createButton: 'Create password',
      changeButton: 'Change password',
      alreadyHavePassword: 'Already have a password?',
      passShouldNotMatch: 'Passwords should not match',
      wrong: 'Incorrect password',
    },
    otp: {
      title: "Enter the confirmation code",
      code: "Check your inbox and spam folder for the verification code, sent to:",
      nocode: "Didn't get the code?",
      resend: "Resend",
      resendSuccess: "Code is resent successfully",
      next: "Next",
      wrong: "Wrong code",
      wait: "You can send requests only once in 60 seconds. Wait ",
      seconds: " seconds to send new request"
    },
    mail: {
      confirmationLinkSent: 'Confirmation link sent to your email',
      description:
        'To complete registration, go to your email and follow the link.',
      checkSpam: 'If you do not see the email – check your spam folder.',
      didNotReceiveEmail: 'Didn’t receive the email?',
      sendAgain: 'Send again',
      sendCooldown:
        'Invite to the provided email is already sent. Please try again later.',
      countdown: 'Next try available in ',
    },
    restore: {
      send: 'Send',
      email: 'Email',
      instructionLinkSent: 'Password reset instructions sent to your email',
      description:
        'To complete password reset, go to your email and follow the instructions.',
      captcha: 'Captcha',
      enterCaptcha: 'Enter the characters from the image',
      reloadCaptcha: 'Refresh',
    },
  },
  instruction: {
    step: 'Step',
    final: 'Final',
    image: 'Instruction',
  },
  provider: {
    name: {
      rf: 'Bank card (RF)',
      cloud: 'Bank card (except Russia)',
      crypto: 'Payment with cryptocurrency',
      payze: 'Bank card (except Russia, Uzbekistan)',
      ruCloudPayments: "Bank Card (in Russia)"
    },
  },
  connection: {
    full: 'Unable to create a new connection. The maximum number of connections has been reached. Delete one of the existing connections to create a new one',
    error: {
      create: 'There was an error connecting',
    },
    actions: 'Actions',
    downloadConfig: 'Download VPN configuration',
    whichDevice:
      'To which device do you want to download the VPN configuration file?',
    howToInstall: 'How to install',
    changeDevice: 'Change device',
    delete: 'Delete',
    confirm: {
      changeDevice: 'Are you sure you want to change the device?',
      delete: 'Are you sure you want to delete the connection?',
    },
    notify: {
      successDeleted: 'Connection successfully deleted',
    },
    list: 'Your devices and connections',
    add: 'add device',
    addNewDevice: 'Add a new device',
    cancelAdding: 'Close',
    name: 'Private Gate',
    server: 'Connection server',
    login: 'User name',
    password: 'Password',
    mac: {
      title: {
        name: 'Display Name',
        server: 'Server Address',
        remote: 'Remote ID',
        user: 'Username',
        password: 'Password',
      },
    },
    windows: {
      title: {
        provider: 'VPN Provider',
        name: 'Connection Name',
        server: 'Server Name or Address',
        vpnType: 'VPN Type',
        dataType: 'Type of Sign-in info',
        user: 'User name',
        password: 'Password',
      },
      value: {
        provider: 'Windows (built-in)',
        vpnType: 'IKEv2',
        dataType: 'Username and password',
      },
    },
    iphone: {
      title: {
        description: 'Description',
        server: 'Server',
        remote: 'Remote ID',
        user: 'Username',
        password: 'Password',
      },
    },
    android: {
      title: {
        server: 'Server',
        user: 'Username',
        password: 'Password',
      },
    },
  },
  country: {
    selectCountry: 'Select the country to connect to',
    selectCountryDescription:
      'The country to which your device will be connected, in the future you can change:',
    name: {
      empty: '🏳️ Not selected',
      kz: '🇰🇿 Kazakhstan',
      ka: '🇰🇿 Kazakhstan',
      ge: "🇩🇪 Germany",
      un: "🇦🇪 Arab Emirates",
      de: '🇩🇪 Germany',
      us: '🇺🇸 USA',
      ru: '🇷🇺 Russia',
      nl: '🇳🇱 Netherlands',
      sg: '🇸🇬 Singapore',
      fr: '🇫🇷 France',
      gb: '🇬🇧 United Kingdom',
      ae: '🇦🇪 Arab Emirates',
      tr: '🇹🇷 Turkey',
    },
    confirm: {
      change: 'Are you sure you want to change the country?',
    },
    notify: {
      successChanged: 'Country successfully changed',
    },
  },
  payment: {
    title: 'Payment',
    paymentMethod: 'Payment method',
    selectPaymentMethod: 'Select payment method',
    pay: 'Pay',
    disclaimer:
      'The currency of the debiting may differ, but the total actual amount does not change from this. To complete the payment, click on the "Pay" button.',
  },
  tariff: {
    name: {
      free: 'Free',
      freePremium: 'Free premium version',
      premium: 'Premium',
    },
    bandwidth: {
      bytes: 'Bits/s',
      kbytes: 'Kbits/s',
      mbytes: 'Mbits/s',
    },
    params: {
      remaining: 'Remaining',
      speed: 'Speed',
      deviceCount: 'Number of devices',
      discount: 'Discount',
      monthly: 'Monthly',
      free: 'Free',
      paidTo: 'Tariff paid until',
    },
    activateFor: 'Subscribe to Premium for',
    renewFor: 'Renew Premium for',
    renew: 'Renew Premium',
    change: 'Change tariff',
    connectFree: 'Connect for free',
    popularChoice: 'Popular choice',
    beneficial: 'Beneficial!',
    availableList: 'Available plans',
    current: 'Selected tariff',
  },
  currency: {
    usd: '$',
    rub: '₽',
  },
  promoCode: {
    title: 'Promo code',
    enter: 'Enter promo code',
    enterPromoCodeToGetOrExtendPremium:
      'Get or extend Premium tariff by entering a promo code',
    activateButton: 'Activate',
    yourPromoCode: 'Your promo code',
    confirmButton: 'Confirm',
    have: 'Have a promo code?',
    apply: 'Apply',
    error: 'The promo code is not correct or has already been activated',
    success: 'Yay, your promo code is activated!',
  },
  installation: {
    app: {
      label: 'Via the X-Ray app',
      time: 'Less than a minute',
      description:
        'X-Ray technology (VLESS) for regions with high levels of censorship. The latest VPN protocol that masks your traffic on the Internet.',
      benefits:
        'Cannot be blocked*Works anytime, anywhere*Easy and quick installation*Stable*Works on any device',
      freeDescription: `X-Ray VPN protocol (via app) is available only on Premium plan.

To continue using a VPN that cannot be blocked, please buy Premium plan.
        `,
      recommended: 'Recommended',
    },
    manual: {
      label: 'Manual Setup',
      time: '≈ 4 minutes, but more reliable',
      description:
        'No apps. Apps can track your actions and share data with advertisers and other third parties. Installing a VPN manually directly on your device without third-party apps.',
    },
    config: {
      label: 'Install VPN configuration',
      time: '≈ 2 minutes and you’re free',
      description:
        'No apps. Apps can track your actions and send data to advertisers and other third parties. We provide a clean VPN configuration that you can install yourself directly on your device without third-party apps.',
    },
    select: 'Choose installation method',
    description:
      'Each is suitable for different preferences: from simplicity to full control.',
    finish:
      'Congratulations!\nYou have successfully installed Private Gate VPN!',
  },
  device: {
    select: 'Select your device',
    selectDeviceToProtect:
      'Start safe internet surfing by selecting a device to protect',
  },
  xray: {
    connection: 'App X-Ray',
    remaining: 'Remaining: ',
    duplicateConnection:
      'You have already created a reference key for the selected country. Use the key issued earlier!',
    createError: 'An error occurred while creating a connection',
    countryChangeHint:
      '⚠️ If you change VPN output country, the current connection will no longer work. On Premium tariff you can create 3 connections with different countries and use them simultaneously on different devices. Are you sure you want to change the output VPN country on the current connection?',
  },
  'non-app': {
    connection: 'NO-Apps',
  },
  guide: {
    app: {
      title: 'Follow the instructions for “Application” method',
      foxray: 'Download FoXray',
      streisand: 'Download Streisand',
      hiddifynext: 'Download Hiddify-Next',
      rayn: 'Download 2RayN',
      linkKey: 'Link key'
    },
    manual: { title: 'Follow the instructions for “Manual setup”' },
    config: {
      title: 'Follow the instructions for “Through Configurations”',
      download: 'Download VPN configuration file',
      downloadApp: 'Download app',
    },
    description: 'Use the video guide',
    stepByStep: 'Or step by step instructions',
    mac: {
      app: {
        step: {
          1: {
            title: 'Install the App',
            description: {
              1: 'From the list of devices, select "iOS"',
              2: 'Go to the App Store',
              3: 'Install the FoXray app',
            },
          },
          2: {
            title: 'Open the App',
            description: {
              1: 'Open the installed application',
              2: 'Agree to the privacy policy (button "Agree" in the bottom right corner)',
            },
          },
          3: {
            title: 'Copy VPN Key',
            description: {
              1: `Copy the key link `,
            },
          },
          4: {
            title: 'Insert the key',
            description: {
              1: 'Open the installed application',
              2: 'On the top right of the screen, tap on the tablet folder icon',
              3: 'If necessary, tap "Add from clipboard" and "Allow pasting"',
              4: 'VPN key imported!',
            },
          },
          5: {
            title: 'Start the VPN',
            description: {
              1: 'Click on the start button next to the imported key (it does not matter whether it is the first or the second one)',
              2: 'Congratulations, VPN is launched!',
              3: 'Safe Internet surfing with Private Gate',
            },
          },
        },
      },
      config: {
        step: {
          1: {
            title: 'Download the configuration file',
            description: {
              1: 'Click on the VPN configuration file and download it',
              2: 'Click “Show in Finder”',
            },
          },
          2: {
            title: 'Install the VPN config',
            description: {
              1: 'In “Finder”, launch the VPN configuration file (usually located at the top of the downloads)',
              2: 'A “Profile Installation” notification will appear in the top right corner of the screen',
            },
          },
          3: {
            title: 'Enter profiles',
            description: {
              1: 'Go to “System Preferences” (in the top left corner of the screen)',
              2: 'In the search field, type “profiles”',
              3: 'Scroll down in the right column and click on “Profiles”',
            },
          },
          4: {
            title: 'Click on “Private Gate”',
            description: {
              1: 'Click on the “Private Gate” profile',
            },
          },
          5: {
            title: 'Click “Install”',
          },
          6: {
            title: 'Click “Install”',
          },
          7: {
            title: 'Enable VPN display',
            description: {
              1: 'Go to “System Preferences” (apple icon)',
              2: 'Click on “Control Center” (in the middle of the menu)',
              3: 'In the right part of the menu at the bottom, find “VPN”',
              4: 'In the “VPN” item, change the setting to “Show in menu bar”',
            },
          },
          8: {
            title: 'Easily turn on VPN',
            description: {
              1: 'In the top right corner of the screen (where the clock is), click on the VPN icon (five bars)',
              2: 'Click “Connect Private Gate” / “Disconnect Private Gate”',
              3: 'Enjoy safe internet surfing with Private Gate!',
            },
          },
        },
      },
      manual: {
        step: {
          1: {
            title: 'Enter “system preferences”',
            description: {
              1: 'In the top left corner, click on the Apple icon',
              2: 'Select "System Preferences"',
            },
          },
          2: {
            title: 'Add VPN configuration',
            description: {
              1: 'On the left part of the menu, select “Network”',
              2: 'On the right part of the menu, click on the icon with three dots',
              3: 'Select "Add VPN Configuration" - “IKEv2”',
            },
          },
          3: {
            title: 'Copy and paste data',
            description: {
              1: 'Go to the bot (or website)',
              2: 'Copy and paste the data into the corresponding fields:',
              3: 'Authentication-Username',
              4: 'Click “Create”',
            },
          },
          4: {
            title: 'Enable VPN display',
            description: {
              1: 'Go to “System Preferences” (apple icon)',
              2: 'Click on “Control Center” (in the middle of the menu)',
              3: 'In the right part of the menu at the bottom, find “VPN”',
              4: 'In the “VPN” item, change the setting to “Show in menu bar”',
            },
          },
          5: {
            title: 'Easily turn on VPN',
            description: {
              1: 'In the top right corner of the screen (where the clock is), click on the VPN icon (five bars)',
              2: 'Click “Connect Private Gate” / “Disconnect Private Gate”',
              3: 'Enjoy safe internet surfing with Private Gate!',
            },
          },
        },
      },
    },
    windows: {
      app: {
        step: {
          1: {
            title: 'Download program',
            description: {
              1: 'Select "Windows" from the list of devices',
              2: 'Confirm opening the link. Downloading starts',
            },
          },
          2: {
            title: 'Launch the  downloaded file',
            description: {
              1: 'In the browser that opens, click on the "last downloaded file" button',
              2: 'Click on the last downloaded file. This will be the archive with the program',
            },
          },
          3: {
            title: 'Extract archive',
            description: {
              1: 'In the opened file archiver click on the "Extract" button',
            },
          },
          4: {
            title: 'Save the program',
            description: {
              1: 'Select the path to save the program files',
              2: 'After selecting the saving path, be sure to write "\\hiddify" at the end of it',
              3: 'It is better to choose the saving path: "C:\\Program Files\\hiddify"',
            },
          },
          5: {
            title: 'Сreating Shortcut',
            description: {
              1: 'Go to Explorer and enter the folder where you saved the program',
              2: 'Right-click on the "HiddifyN.exe" file',
              3: 'Choose: "Send" - "To desktop"',
              4: 'The program shortcut is created on the desktop',
            },
          },
          6: {
            title: 'Run the program',
            description: {
              1: 'On the desktop, run "HiddifyN" as administrator',
              2: 'To do this, right-click on the "HiddifyN" shortcut',
              3: 'Click on "Run as Administrator"',
            },
          },
          7: {
            title: 'Copy VPN Key',
            description: {
              1: `Copy the key link `,
            },
          },
          8: {
            title: 'Insert the key',
            description: {
              1: 'Return to the "Hiddify" program. To do this, click on the minimized program icon at the bottom of the program',
              2: 'In the "Hiddify" program in the upper right corner click on "Import" (icon with "+")',
              3: 'Congratulations, the key has been imported!',
            },
          },
          9: {
            title: 'Start the VPN',
            description: {
              1: 'Click on the big icon in the center of the program',
              2: 'Safe Internet surfing with Private Gate!',
            },
          },
        },
      },
      config: {
        step: {
          1: {
            title: 'Start – Settings',
            description: {
              1: 'Go to “Start” - “Settings” or press "Win + I" on your keyboard',
            },
          },
          2: {
            title: 'Network & Internet',
            description: {
              1: 'Navigate to the “Network & Internet” section',
            },
          },
          3: {
            title: 'Add a VPN',
            description: {
              1: 'Select “VPN” (in the left menu) then click on “Add a VPN connection” (on the right)',
              2: 'Click on “Add a VPN connection” (on the right)',
            },
          },
          4: {
            title: 'Copy and Paste',
            description: {
              1: 'Next, simply copy and paste the data received from the bot (or website) into the corresponding fields in Settings',
            },
          },
          5: {
            title: 'Easily turn on VPN',
            description: {
              1: `You can turn the VPN on and off with one button:
              1.  Click on the connection icon in the bottom right corner (where the clock is)
              2.  Connect to the VPN
    
              Enjoy safe internet surfing with Private Gate!`,
            },
          },
        },
      },
      manual: {
        step: {
          1: {
            title: 'Start – Settings',
            description: {
              1: 'Go to “Start” - “Settings” or press "Win + I" on your keyboard',
            },
          },
          2: {
            title: 'Network & Internet',
            description: {
              1: 'Navigate to the “Network & Internet” section',
            },
          },
          3: {
            title: 'Add a VPN',
            description: {
              1: 'Select “VPN” (in the left menu) then click on “Add a VPN connection” (on the right)',
              2: 'Click on “Add a VPN connection” (on the right)',
            },
          },
          4: {
            title: 'Copy and Paste',
            description: {
              1: 'Next, simply copy and paste the data received from the bot (or website) into the corresponding fields in Settings',
            },
          },
          5: {
            title: 'Easily turn on VPN',
            description: {
              1: `You can turn the VPN on and off with one button:
              1.  Click on the connection icon in the bottom right corner (where the clock is)
              2.  Connect to the VPN`,
              2: 'Enjoy safe internet surfing with Private Gate!',
            },
          },
        },
      },
    },
    iphone: {
      app: {
        step: {
          1: {
            title: 'Install the App',
            description: {
              1: 'From the list of devices, select "iOS"',
              2: 'Go to the App Store',
              3: 'Install the Streisand app',
            },
          },
          2: {
            title: 'Open the app',
            description: {
              1: 'Open the installed application',
            },
          },
          3: {
            title: 'Copy VPN key',
            description: {
              1: `Copy the key link `,
            },
          },
          4: {
            title: 'Insert the key',
            description: {
              1: 'Open the installed application',
              2: 'At the top of the screen, tap on +plus',
              3: 'Tap "Add from clipboard" and "Allow pasting"',
              4: 'VPN key imported!',
            },
          },
          5: {
            title: 'Start the VPN',
            description: {
              1: 'Click on the start button',
              2: 'Congratulations, VPN is launched!',
              3: 'Safe Internet surfing with Private Gate!',
            },
          },
        },
      },
      config: {
        step: {
          1: {
            title: 'Tap on the file',
            description: {
              1: 'Tap on the Private Gate VPN configuration file that was received in the bot (or on the website)',
            },
          },
          2: {
            title: 'Save the file',
            description: {
              1: "In the bottom left corner of the screen, tap on the 'share file' icon (a square with an arrow pointing up)",
            },
          },
          3: {
            title: 'Save to Files',
            description: {
              1: "Scroll down and select 'Save to Files' from the menu",
            },
          },
          4: {
            title: 'Save',
            description: {
              1: 'Save the Private Gate VPN configuration file to your iPhone or iCloud Drive',
            },
          },
          5: {
            title: 'Tap done',
            description: {
              1: "In the upper right corner of the screen, tap 'Done'",
              2: "Go to the iPhone's main screen",
            },
          },
          6: {
            title: 'Go to Files',
            description: {
              1: 'On the main screen of the iPhone, swipe down',
              2: "In the search bar (at the bottom), write 'files'",
              3: "Go to 'Files' (at the top of the screen)",
            },
          },
          7: {
            title: 'Install the VPN config',
            description: {
              1: "Go to the config's save location (iPhone memory or iCloud Drive)",
              2: "Tap on the 'Private Gate' profile (file is at the top of the list)",
            },
          },
          8: {
            title: 'Profile loaded',
            description: {
              1: 'You will receive a notification about the successful upload of the VPN profile',
            },
          },
          9: {
            title: 'Enter profiles',
            description: {
              1: 'Go to Settings',
              2: "Tap on 'Profile Downloaded' (at the top of the screen)",
            },
          },
          10: {
            title: "Tap 'Install'",
          },
          11: {
            title: "Tap 'Install'",
          },
          12: {
            title: 'Profile installed',
            description: {
              1: "In the upper right corner of the screen, tap 'Done'",
              2: 'Return to Settings',
            },
          },
          13: {
            title: 'Turn on VPN Easily',
            description: {
              1: 'Go to Settings',
              2: "Tap on 'VPN' to Connect / Disconnect VPN",
              3: 'Safe internet surfing with Private Gate!',
            },
          },
        },
      },
      manual: {
        step: {
          1: {
            title: 'Settings',
            description: {
              1: 'Enter Settings (gear icon ⚙️)',
            },
          },
          2: {
            title: 'Search bar',
            description: {
              1: 'In Settings, tap on the search bar (at the top of the screen)',
              2: "In the search bar, write - 'vpn'",
              3: "Tap on 'VPN & Device Management'",
            },
          },
          3: {
            title: "Select 'VPN'",
            description: {
              1: "Tap on 'VPN' (at the top of the screen)",
            },
          },
          4: {
            title: 'Add Config',
            description: {
              1: "Tap on 'Add VPN Configuration'",
            },
          },
          5: {
            title: 'Go to the bot or website',
            description: {
              1: 'Go to the bot (or the website)',
              2: 'Copy and paste the data into the corresponding fields',
            },
          },
          6: {
            title: 'Copy and paste',
            description: {
              1: 'Copy and paste the data into the corresponding fields:',
              2: "Tap 'Done'",
            },
          },
          7: {
            title: 'Turn on VPN Easily',
            description: {
              1: 'Go to Settings',
              2: "Tap on 'VPN'",
              3: `Tap on 'Not Connected'
              Safe internet surfing with Private Gate!`,
            },
          },
        },
      },
    },
    android: {
      app: {
        step: {
          1: {
            title: 'Install the app',
            description: {
              1: 'From the list of devices, select "Android"',
              2: 'Go to the Google Play',
              3: 'Install the Hiddify app',
            },
          },
          2: {
            title: 'Open the app',
            description: {
              1: 'Open the installed application',
              2: 'Set the language, select the region, leave the analytics collection on or off it',
              3: 'Click Next',
            },
          },
          3: {
            title: 'Copy VPN Key',
            description: {
              1: `Copy the key link `,
            },
          },
          4: {
            title: 'Insert the key',
            description: {
              1: 'Open the installed application',
              2: 'At the top of the screen, tap on +plus',
              3: 'Tap "Add from clipboard"',
              4: 'Enable notifications',
            },
          },
          5: {
            title: 'Allow request',
            description: {
              1: 'Allow connection request',
              2: 'VPN key imported!',
            },
          },
          6: {
            title: 'Start the VPN',
            description: {
              1: 'Click on the start button',
              2: 'Congratulations, VPN is launched!',
              3: 'Safe Internet surfing with Private Gate!',
            },
          },
        },
      },
      config: {
        step: {
          1: {
            title: 'Go to Google Play',
            description: {
              1: 'You can install Private Gate VPN on Android using the strongSwan app. This is a secure application that does not transmit data and is openly available.\n\n1. Follow the link to Google Play\n2. For Huawei users',
            },
          },
          2: {
            title: 'Install strongSwan',
            description: {
              1: 'Download and install the strongSwan VPN application (in Google Play)',
            },
          },
          3: {
            title: 'Tap on the file',
            description: {
              1: 'Tap on the Private Gate VPN configuration file that was received in the bot (or on the website)',
            },
          },
          4: {
            title: 'Save the file',
            description: {
              1: "To save the VPN configuration file, select 'Chrome' or the browser you are using",
            },
          },
          5: {
            title: 'Go to strongSwan',
            description: {
              1: 'Go to the strongSwan app',
              2: "In the upper right corner of the screen, press 'three dots'",
              3: "Press 'Import VPN profile'",
            },
          },
          6: {
            title: 'Find the config',
            description: {
              1: 'In the upper corner of the screen, swipe right',
              2: "Press 'This Week'",
              3: 'Sorting completed successfully',
            },
          },
          7: {
            title: 'Select the config',
            description: {
              1: "Press on the Private Gate VPN configuration file The file will be at the top of the list (file format 'user2023110121035312413.profile')",
            },
          },
          8: {
            title: 'Last step',
            description: {
              1: 'All that remains is to enter the password',
              2: 'Return to the Telegram bot (or to the website)',
            },
          },
          9: {
            title: 'Copy the password',
            description: {
              1: 'Copy the password that you received in the Telegram bot (or on the website)',
            },
          },
          10: {
            title: 'Paste the password',
            description: {
              1: 'Paste the copied password',
              2: "Press 'Import' (in the upper right corner of the screen)",
            },
          },
          11: {
            title: 'Start the VPN',
            description: {
              1: "Press on 'Private Gate VPN'",
            },
          },
          12: {
            title: "Press 'OK'",
          },
          13: {
            title: "Press 'OK'",
          },
          14: {
            title: "Press 'Allow'",
          },
          15: {
            title: 'VPN connected',
            description: {
              1: 'Congratulations! You are connected to the VPN! Safe internet surfing with Private Gate!',
            },
          },
        },
      },
      manual: {
        step: {
          1: {
            title: 'Settings',
            description: {
              1: 'You can install Private Gate VPN on Android using the strongSwan app. This is a secure application that does not transmit data and is openly available.\n\n1. Follow the link to Google Play\n2. For Huawei users',
            },
          },
          2: {
            title: 'Install strongSwan',
            description: {
              1: 'Download and install the strongSwan VPN application (in Google Play)',
            },
          },
          3: {
            title: 'Go to strongSwan',
            description: {
              1: 'Go to the strongSwan app',
              2: "In the upper right corner of the screen, press 'ADD VPN PROFILE'",
            },
          },
          4: {
            title: 'Go to the bot or website',
            description: {
              1: 'Return to the Telegram bot (or to the website)',
              2: 'Copy and paste the data into the corresponding fields',
            },
          },
          5: {
            title: 'Copy and paste',
            description: {
              1: 'Copy and paste the data into the corresponding fields:',
              2: "Press 'Save' (in the upper right corner)",
            },
          },
          6: {
            title: 'Turn on VPN Easily',
            description: {
              1: `1. Press on Private Gate VPN
              2. Congratulations! You are connected to the VPN! Safe internet surfing with Private Gate!`,
            },
          },
        },
      },
    },
    success: {
      mac: 'For Mac (macOS)',
      windows: 'For PC (Windows)',
      iphone: 'For iPhone (iOS)',
      android: 'For Android',
    },
    gift: {
      title: 'Hooray, you have been activated Premium tariff for 7 days!',
      subtitle: 'This is a gift from Private Gate VPN',
      description:
        'Afterwards, you can use the free tariff with speed limitation or return to the advantages of Premium subscription',
      thanks: 'Thank you!',
    },
  },
  referralLink: {
    title: "Bring a friend!",
    description: `Did you know, that you will have 7 days of Premium Subscription as a gift for every invited friend?

And it is not all:
🎁 If someone follows your link, they will have 7 days of Premium Subscription as a gift.
🎁 If invited pays for a subscription, they will have 1 month as a bonus, and you will have 3 months of Premium.`,
    link: "Here is your referral link:",
    userActivations: "Total amount of invited users:",
    userPaymentActivations: "Total amount of payments by invited users:",
    copyLink: "Copy link",
    copySuccess: "The link was successfully copied."
  }
};
