import React from 'react';
import styles from './styles.module.scss';
import { Button, Flex, MaterialInput, Modal, Typography } from 'shared/_ui';
import { useStoreUiControls } from 'shared/lib';
import { CONTROL_IDS } from 'shared/consts';
import { useTranslation } from 'react-i18next';
import { useFetchReferralLink } from 'shared/api/referralLinkApi';
import { notify } from 'shared';

const ReferralLinkModal: React.FC = () => {
  const { t } = useTranslation();

  const { isOpen, close } = useStoreUiControls(CONTROL_IDS.REFLINK_MODAL);
  const { data: linkInfo } = useFetchReferralLink(null);
  const onCopyLink = () => {
    navigator.clipboard.writeText(linkInfo?.refferalLink ?? "");
    notify.success(t("referralLink.copySuccess"))
  }
  return (
  <Modal isOpen={isOpen} onClose={close}>
    <Flex direction="column" className={styles.content} spacing={32}>
      <Typography.Title level={1} className={styles.center}>
        {t("referralLink.title")}
      </Typography.Title>
      <Typography.Text className={styles.center}>{t("referralLink.description")}</Typography.Text>
      <div>
      <Typography.Text className={styles.label}>{t("referralLink.link")}</Typography.Text>
      <MaterialInput disabled={true} className={styles.input} placeholder={linkInfo?.refferalLink}></MaterialInput>
      </div>
      <div>
      <Typography.Text className={styles.label}>{t("referralLink.userActivations")}</Typography.Text>
      <MaterialInput disabled={true} className={styles.input} placeholder={linkInfo?.userActivations.toString()}></MaterialInput>
      </div>
      <div>
      <Typography.Text className={styles.label}>{t("referralLink.userPaymentActivations")}</Typography.Text>
      <MaterialInput disabled={true} className={styles.input} placeholder={linkInfo?.userPaymentActivations.toString()}></MaterialInput>
      </div>
      <Button onClick={onCopyLink} type="primary">{t("referralLink.copyLink")}</Button>
    </Flex>
  </Modal>
  );
};

export { ReferralLinkModal };
