import { $rtkApi } from './rtkApi';

export interface ReferralLinkInfo {
    refferalLink : string,
    userActivations : number,
    userPaymentActivations : number
}

const referralLinkApi = $rtkApi.injectEndpoints({
    endpoints: (build) => ({
      fetchReferralLink: build.query<ReferralLinkInfo, null>({
        query: () => ({
          url: `/api/referral-link`,
        }),
        providesTags: ['UserInfo'],
      }),
    }),
    overrideExisting: false,
  });

export const useFetchReferralLink = referralLinkApi.useFetchReferralLinkQuery;
export const useFetchReferralLinkResult =
    referralLinkApi.endpoints.fetchReferralLink.useQueryState;