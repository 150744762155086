import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { XrayServer, useFetchXrayServers } from 'features/XrayCountrySelect';
import { useSliderContext } from '../../../model';
import { MAP_COUNTRY_TO_ICON } from './model';
import { Typography } from 'shared/_ui';

const Chevron = () => (
  <svg
    width="17"
    height="10"
    viewBox="0 0 17 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 9L8.5 1L1 9"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const FlagPlaceholder = () => (
  <div className={styles.flagPlaceholder}>
    <Typography.Text size={5}>?</Typography.Text>
  </div>
);

export const CountrySelectDropdown = () => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<XrayServer[]>([]);
  const [open, setOpen] = useState(true);

  const { data: servers } = useFetchXrayServers(null);

  const { setChosenServerId, setChosenCountry } = useSliderContext();

  useEffect(() => {
    if (servers && servers?.length > 0) {
      setOptions(servers);

      setChosenServerId(servers[1].id);
      setChosenCountry(servers[1].country);
      setOptions([servers[1],servers[0],...servers.slice(2)]);
      if(installationType !== "app") {
        setChosenServerId(servers[1].id);
        setChosenCountry(servers[1].country);
      }
    }
  }, [servers]);
  useEffect(() => {
    if (!contentRef.current) return;

    if (open) {
      contentRef.current.style.height = `${contentRef.current?.scrollHeight}px`;
      return;
    }

    contentRef.current.style.height = '0px';
  }, [open]);

  const contentRef = useRef<HTMLDivElement>(null);
  
  const handleInactiveElementClick = (option: XrayServer, index: number) => {
    setOptions((prev) => {
      const newElements = [...prev];
      const clickedOptionIndex = index + 1;
      [newElements[clickedOptionIndex], newElements[0]] = [
        newElements[0],
        newElements[clickedOptionIndex],
      ];

      return newElements;
    });

    setChosenServerId(option.id);
    setChosenCountry(option.country);
  };

  const handleActiveElementClick = () => setOpen((prev) => !prev);

  const SERVER_COUNTRY_TO_NAME_MAP: Record<string, string> = {
    KZ: t(`country.name.kz`),
    RU: t(`country.name.ru`),
    GB: t(`country.name.gb`),
    SG: t(`country.name.sg`),
    US: t(`country.name.us`),
    FR: t(`country.name.fr`),
    NL: t(`country.name.nl`),
    DE: t(`country.name.de`),
    AE: t(`country.name.ae`),
    TR: t(`country.name.tr`),
  };
  const {installationType} = useSliderContext();

  const getOptionContents = (country: string) => {
    return (<div className={styles[`option-content`]}>
      {MAP_COUNTRY_TO_ICON?.[country] || <FlagPlaceholder />}
      {SERVER_COUNTRY_TO_NAME_MAP?.[country]?.split(' ').splice(1).join(' ') ||
        country}
    </div>)
  };
  return (
    <div className={styles.wrapper}>
      {options[0] && (
        <div>
          <p
            className={classNames(styles.option, styles.active)}
            key={options[0].id}
            onClick={handleActiveElementClick}
          >
            <div className={styles[`option-content`]}>
              {getOptionContents(options[0].country)}
            </div>
            {options.slice(1).length > 0 && (
              <div
                className={classNames({
                  [styles.icon]: true,
                  [styles.open]: open,
                })}
              >
                <Chevron />
              </div>
            )}
          </p>
        </div>
      )}
      {options.slice(1).length > 0 && (
        <div
          ref={contentRef}
          className={classNames({
            [styles.content]: true,
            [styles.open]: open,
          })}
        >
          {options.slice(1).map((option, index) => {
            if( installationType !== "app" && (option.country === "TR" || option.country === "NL") ) return <></>
            return <p
              className={styles.option}
              key={option.id}
              onClick={() => {
                handleInactiveElementClick(option, index);
              }}
            >
              <div className={styles[`option-content`]}>
                {getOptionContents(option.country)}
              </div>
            </p>
            })}
        </div>
      )}
    </div>
  );
};
